import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

export const query = graphql`  
  query ResourceQuery($id: Int!) {
    strapiResource(strapiId: { eq: $id }) {
      strapiId
      slug
      title
    }
  }
`

const Resource = ({ data }) => {
    const resource = data.strapiResource
    return (
        <Layout>
            <div>
                <div
                    id="banner"
                    className="uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light uk-padding uk-margin"
                >
                    <h1>{resource.title}</h1>
                    <p>https://netlify-gatsby-test.teachstarter.com/resource/{resource.slug}</p>
                </div>

                <div className="uk-section">
                    <div className="uk-container uk-container-small">
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Resource